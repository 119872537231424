.purchase_card {
    background-color: #303134;
    border: 1px solid #303134;
    border-radius: 5px;
    margin-bottom: 2%;
}

.purchase_card h4 {
    background-color: #222;
    color: #fff;
    padding: .9em;
    margin-bottom: 1.3em;
}

.purchase_card p {
    color: #fff;
    margin-left: 2%;
}

.button_red {
    background-color: #dc3545;
    color: #fff;
    text-decoration: none;
    border: none;
    padding: .3em;
    margin: 2% 0 2% 2%;
}

.button_blue {
    background-color: #0079fb;
    color: #fff;
    text-decoration: none;
    border: none;
    padding: .3em;
    margin: 2% 0 2% 2%;
}

.purchase_card_p {
    color: #fff;
    margin-bottom: 1.3em;
}

.purchase_card span {
    font-weight: bold;
}