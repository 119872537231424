.navbar {
    display: flex;
    justify-content: space-between;
    background-color: #343a40;
    padding: 1em;
}

.list {
    display: flex;
    list-style: none;
    align-items: center;
}

.item {
    margin-right: 1em;
}

.item a {
    color: #fff;
    text-decoration: none;
}

.item a:hover {
    color: #ffbb33;
}