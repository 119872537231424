.container {
    display: flex;
    flex-direction: column;
}

.container button {
    text-decoration: none;
    border: none;
    background-color: #0069d9;
    color: #fff;
    padding: .7em 1em;
    cursor: pointer;
    align-items: center;
    transition: 0.5s;
    max-width: 20%;
}

.container label {
    margin-bottom: .6em;
    font-weight: bold;
}

.container input {
    padding: .7em;
    border-radius: 0;
    border: none;
    margin-bottom: 1%;
    max-width: 50%;
}

.container input::placeholder{
    color: #7b7b7b;
}