.client_card {
    background-color: #303134;
    border: 1px solid #303134;
    border-radius: 5px;
    margin: .5%;
    margin-top: 5%;
}

.client_card h4 {
    background-color: #222;
    color: #fff;
    padding: .9em;
    margin-bottom: 1.3em;
}

.client_card p {
    color: #fff;
    padding: .5em;
    margin-bottom: 1.3em;
}

.client_card span {
    font-weight: bold;
}

.client_card a {
    text-decoration: none;
    border: none;
    background-color: #0069d9;
    color: #fff;
    padding: .7em 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: 0.5s;
    margin: 1%;
    width: 30%;
}


.client_card a:hover {
    background-color: #0069d9;
    color: #fff;
}