.container {
    margin-bottom: 1%;
}

.container input {
    padding: .7em;
    border-radius: 0;
    border: none;
    margin: 2% 0% 2% 0%;
}

.container input::placeholder{
    color: #7b7b7b;
}

.red_button button {
    text-decoration: none;
    border: none;
    background-color: #dc3545;
    color: #fff;
    padding: .7em 2em;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: .5s;
    margin-right: .5em;
}

.blue_button button {
    text-decoration: none;
    border: none;
    background-color: #0079fb;
    color: #fff;
    padding: .7em 2em;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: 0.5s;
    margin-right: 2%;
}

.buttons {
    display: flex;
}