.green_button a {
    text-decoration: none;
    border: none;
    background-color: #28a745;
    color: #fff;
    padding: .7em 2em;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: 0.5s;
    margin: 1%;
}


.green_button a:hover {
    background-color: #218838;
    color: #fff;
}

.blue_button a {
    text-decoration: none;
    border: none;
    background-color: #0079fb;
    color: #fff;
    padding: .7em 2em;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: 0.5s;
    margin: 1%;
}


.blue_button a:hover {
    background-color: #0069d9;
    color: #fff;
}

.red_button button {
    text-decoration: none;
    border: none;
    background-color: #dc3545;
    color: #fff;
    padding: .7em 4em;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: 0.5s;
    margin: 1%;
}

.red_button a {
    text-decoration: none;
    border: none;
    background-color: #dc3545;
    color: #fff;
    padding: .7em 4em;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: 0.5s;
    margin: 1%;
}

.buttons {
    display: flex;
}

.container {
    margin: 1%;
}